<template>
  <div class="bg-box">
    <img style="width: 100%" src="../../assets/img/banner.png" alt="" />
    <div class="w">
      <div class="works-top">
        <div class="title">
          <div></div>
          <div>消息中心</div>
        </div>
      </div>

      <div class="no-list" v-show="noticeList.length == 0">暂无通知</div>
      <div class="message-content">
          <div class="infor-box" v-for="(item, index) in noticeList" :key="index">
          <!-- 头像 -->
          <div style="margin-right: 20px;">
            <img style="height:48px;width:48px; border-radius: 50%;" src="../../assets/img/notice.png" alt />
          </div>
          <!-- 内容 -->
          <div class="infor-content">
            <div>【系统公告】{{item.content}}</div>
            <!-- <div style="display: flex;">
                    <div>浏览：1222</div>
                    <div style="margin-left: 30px;">评论：6666</div>
            </div>-->
          </div>
        </div>
      </div>
       
        <!-- 分页 -->
        <el-row type="flex" justify="center" style="margin: 50px 0;">
          <el-pagination
            background
            layout="total,prev, pager, next"
            :page-size="10"
            :total="noticeTotal"
            @current-change="currentChangeNoticeTotal"
          ></el-pagination>
          <!-- @current-change="currentChangeList" -->
        </el-row>
    </div>
  </div>
</template>

<script>
import {getNotice} from '../../assets/api/index';
export default {
  data () {
    return {
      noticeList: [],
      noticeTotal: 0
    }
  },
  mounted () {
    this.getNotice(0)
  },
  methods: {
    currentChangeNoticeTotal (page) {
      this.getNotice(page)
    },
    // 消息通知
    async getNotice (page) {
      const params = {
        page: page
      }
      const res = await getNotice(params)
      if (res.code==200) {
        this.noticeList = res.data.list ? res.data.list : []
        this.noticeTotal = Number(res.data.total)
      }
    }
  }
}
</script>

<style scoped>
.message-content{
    /* width: 1000px; */
    margin: 40px auto 0;
}
.infor-box {
  height: 95px;
  /* background-color: pink; */
  display: flex;
  align-items: center;
}
.infor-content {
  display: flex;
  height: 95px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ececec;
  padding-right: 50px;
  box-sizing: border-box;
}
.infor-content div {
  color: #666666;
  font-size: 17px;
  line-height: 25px;
}
.works-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.title {
  display: flex;
  align-items: center;
}
.title div:nth-child(1) {
  width: 8px;
  height: 24px;
  background-color: #fbad51;
}
.title div:nth-child(2) {
  font-size: 20px;
  color: #000;
  margin-left: 10px;
  font-weight: 600;
}
</style>